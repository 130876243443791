
:root {
  --gradient: linear-gradient(
    140deg,
    #F8A3A8,
    #F3C6A5,
    #E5E1AB,
    #9CDCAA,
    #96CAF7,
    #BFB2F3,
    #845ec2
  );
  --gradientVert: linear-gradient(
    90deg,
    #F8A3A8,
    #F3C6A5,
    #E5E1AB,
    #9CDCAA,
    #96CAF7,
    #BFB2F3,
    #845ec2
  );
  --gradient1: linear-gradient(
    90deg,
    #F8A3A8,
    #F3C6A5,
    #E5E1AB
  );
  --gradient2: linear-gradient(
    90deg,
    #E5E1AB,
    #9CDCAA,
    #96CAF7
  );
  --gradient3: linear-gradient(
    90deg,
    #96CAF7,
    #BFB2F3,
    #845ec2
  );
}

/* :root {
  --gradient: linear-gradient(
    140deg,
    #845ec2,
    #d65db1,
    #ff6f91,
    #ff9671,
    #ffc75f,
    #f9f871
  );
} */

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif;
  background-color: #000000;
  color: #fff;
  line-height: 1.6;
}

/* Multiple Pages */

.pageHeader {
  font-size: 7vh;
  background-color: #000000;
  color: #ff6a95;
  height: 8vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
@media screen and (max-width: 750px) {
  .pageHeader {
    font-size: 40px;
  }
} 
@media screen and (max-height: 500px) {
  .pageHeader {
    font-size: 40px;
  }
}

.loading {
  font-weight: 600; 
  text-align: center;
  color: #ff6a95;
}

.loading a {
  text-decoration: none;
  color: #ff6a95;
}

 .TechStackMatlab {
  height: 30px;
  width: 30px;
 }

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* NotFound Page */

.oops {
  margin-bottom: 2vh;
  font-weight: 700;
  font-size: 12vh;
  text-align: center;
  color: transparent;
  background-image: var(--gradient);
  background-size: 80%;
  background-clip: text;
  -webkit-background-clip: text;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}

/* Navbar */
.navbar {
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #000000; 
  z-index: 999;
}
@media screen and (max-height: 800px) {
  .navbar {
    height: 90px;
  }
} 

.myIcon {
  height: 9.5vh;
  margin-top: 0.5vh;
  margin-left: 2vw;
}
@media screen and (max-height: 800px) {
  .myIcon {
    height: 85px;
    margin-top: 5px;
  }
} 

.twenty {
  width: 15vw;
  margin-right: 5vw;
}

.dropIcon {
  color: #ff6a95;
  cursor: pointer;
  z-index: 1000;
}

.dropdown {
  background-color: rgba(0,0,0,0.8);
  position: absolute;
  width: 45vw;
  height: auto;
  margin-top: 35vh;
  padding-top: 2vh;
  margin-left: 55vw;
  z-index: 1;
}
@media screen and (max-height: 800px) {
.dropdown {
  margin-top: 310px;
}
}
@media screen and (min-width: 721px) {
  .dropdown {
    display: none;
  }
  }

.menuList {
  list-style: none;
}

.dropItem a{
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  line-height: 50%;
  color: #BFB2F3;
  font-family: -apple-system, BlinkMacSystemFont, "Oxygen",
  sans-serif;
  display: flex;
  text-align: left;
  flex-wrap: wrap;
  margin-left: -15vw;
}

.dropItem a:hover {
  color: #F8A3A8;
  text-decoration: underline;
 }

.dropTitle {
  text-align: left;
}

.eightylg {
  display: flex;
  flex-wrap: wrap;
  text-align: right;
  justify-content: flex-end;
  width: 85vw;
  margin: 2vw;
  line-height: 0.1vh;
}
@media screen and (max-width: 720px) {
  .eightylg {
    display: none;
  }
} 

.eightysm {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 85vw;
  margin-right: 2vw;
}
@media screen and (min-width: 720.1px) {
  .eightysm {
    display: none;
  }
} 

.navTitle {
  font-size: 1.7vw;
  flex-wrap: wrap;
  margin-left: 2vw;
}
@media screen and (max-width: 720px) {
  .navTitle {
    margin-left: 1vw;
    font-size: 14px;
  }
} 
@media screen and (max-width: 620px) {
  .navTitle {
    margin-left: 1vw;
    font-size: 12px;
  }
} 

.navItem {
  text-align: right;
}

.navItem a:hover {
 color: #F8A3A8;
 text-decoration: underline;
}

.navItem a{
  text-decoration: none;
  font-weight: 600;
  font-size: 2vh;
  margin-right: 1vw;
  color: #BFB2F3;
  font-family: -apple-system, BlinkMacSystemFont, "Oxygen",
  sans-serif;
}

/* About Page */

.myPic {
  height: 30vh;
  width: auto;
  border: 1px solid black;
 }

 .SABass {
  height: 30vh;
  width: auto;
 }
 @media screen and (max-height: 500px) {
  .myPic,
  .SABass {
    height: 50vh
  }
}

 .aboutPage {
  text-align: center;
  align-items: center;
 }

 .abt {
  display: flex;
  align-items: center;
  justify-content: center;
 }

 .setLeft {
  width: 35vw;
  float: left;
  height: auto;
  margin-top: 1vh;
  margin-bottom: 1vh;
 }
 
 .setRight {
  width: 60vw;
  float: right;
  text-align: left;
  height: 100%;
  margin-top: 1vh;
  margin-bottom: 1vh;
 }

 .clear {
  clear: both;
}

@media screen and (max-width: 720px) {
  .setLeft {
    float: none;
    height: 30vh;
    width: auto;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 720px) {
  .setRight {
    float: none;
    width: 100%;
    height: auto;
    text-align: center;
  }
}
@media screen and (max-width: 720px) {
  .abt {
    display: inline;
    align-items: center;
    justify-content: center;
    float: none;
  }
} 


.pink {
  color: #ff6a95;
  font-weight: 600;
}

.aboutBody {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2vw;
  margin-right: 2vw;
  font-size: 3vh;
}

@media screen and (max-width: 720px) {
  .aboutBody {
    font-size: 14px;
  }
} 

/* Contact Page */

.form-group {
  margin-bottom: 1vw;
  margin-left: 30vw;
}

@media screen and (max-width: 750px) {
  .form-group {
    margin-left: 5vw;
    width: 100vw;
  }
} 

.form-group input {
  padding: 0.5vw;
  width: 40vw;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 1vh;
  font-family: inherit;
}
@media screen and (max-width: 750px) {
  .form-group input {
    width: 80vw;
  }
} 

.form-group textarea {
  padding: 0.5vw;
  width: 50vw;
  height: 30vh;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 1vh;
  font-family: inherit;
}
@media screen and (max-width: 750px) {
  .form-group textarea {
    width: 80vw;
  }
} 

.formWidth {
  width: 60vw;
}

@media screen and (max-width: 700px) {
  .formWidth {
    width: 90vw;
  }
} 

#g-recaptcha {
  transform: scale(1);
  transform-origin: 0 0 0;
}

@media screen and (max-width: 400px) {
  #g-recaptcha {
    transform: scale(0.7);
    transform-origin: 0 0 0;
  }
} 

.contact-btn {
  background-color: #ff6a95;
  color: #000000;
  margin-top: 1vw;
  font-size: 2vh;
  font-weight: 550;
  border-radius: 1rem;
  width: 20vw;
}

.contact-btn:hover {
  cursor: pointer;
  background-color: #845ec2;
}

.contact-btn:active {
  color: #BFB2F3;
}
@media screen and (max-width: 650px) {
  .contact-btn {
    width: 40vw;
  }
} 

/* Home Page */
.homeColor {
  overflow: hidden;
  height: 90vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: var(--gradient);
  background-size: 1000%;
  animation: bg-animation 20s ease infinite alternate;
}

@media screen and (max-height: 800px) {
  .homeColor {
    height: calc(100vh - 90px);
  }
} 

@keyframes bg-animation {
  0% {background-position: left;}
  100% {background-position: right;}
}

.bubble {
  width: 1vh;
  height: 1vh;
  border: 0.1vh solid white;
  box-shadow: inset 0.1vh -0.1vh 0.2vh #4f42b5;
  border-radius: 70%;
  background-color: none;
  position: absolute;
  top: 98.8vh;
}

.bubble::before {
  position: absolute;
  content: "";
  background-color: white;
  width: 0.2vh;
  height: 0.2vh;
  border-radius: 50%;
  top: 0.25vh;
  right: 0.2vh;
  box-shadow: 0px 0px 0.2vh white;
}

.bubble.a{
  left: 10%;
  animation: bubble2 4s ease-in 2s infinite;
}

.bubble.b {
  left: 3%;
  animation: bubble 3s ease-in 2.9s infinite;
}

.bubble.c {
  left: 20%;
  animation: bubble 10s ease-in 7s infinite;
}

.bubble.d {
  left: 28%;
  animation: bubble 3.6s ease-in 1s infinite;
}

.bubble.e {
  left: 67%;
  animation: bubble2 4.6s ease-in 5s infinite;
}

.bubble.f {
  left: 35%;
  animation: bubble 5s ease-in 6s infinite;
}

.bubble.g {
  left: 15%;
  animation: bubble 4.4s ease-in 4s infinite;
}

.bubble.h {
  left: 50%;
  animation: bubble 3.2s ease-in 4s infinite;
}

.bubble.i {
  left: 75%;
  animation: bubble2 3.6s ease-in 4.2s infinite;
}

.bubble.j {
  left: 85%;
  animation: bubble 4s ease-in 3s infinite;
}

.bubble.k {
  left: 90%;
  animation: bubble2 6s ease-in infinite;
}

@keyframes bubble {
  0% { opacity: 0}
  10%,
  80% { opacity: 1;}
  100% {
      transform: translate(12vh, -89.1vh);
      display: none;
  }
}

@keyframes bubble2 {
  0% { opacity: 0;}
  10%,
  80% { opacity: 1;}
  100% {
      transform: translate(-12vh, -89.1vh);
      display: none;
  }
}
@media screen and (max-height: 800px) {
  @keyframes bubble2 {
    0% { opacity: 0;}
    10%,
    80% { opacity: 1;}
    100% {
        transform: translate(-12vh, calc(95px - 100vh));
        display: none;
    }
  }
} 
@media screen and (max-height: 800px) {
  @keyframes bubble {
    0% { opacity: 0;}
    10%,
    80% { opacity: 1;}
    100% {
        transform: translate(12vh, calc(95px - 100vh));
        display: none;
    }
  }
} 

  .respTitle {
    font-size: 80px;
  }
@media screen and (max-width: 850px) {
  .respTitle {
    font-size: 60px;
  }
} 
@media screen and (max-width: 550px) {
  .respTitle {
    font-size: 50px;
  }
} 
@media screen and (max-width: 450px) {
  .respTitle {
    font-size: 35px;
  }
} 

.homeTitle {
  text-align: center;
  position: relative;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  text-shadow: 1px 1px #575757;
  line-height: 100%;
}

.icon {
  color: #000000;
  height: 50px;
 }

 .icon:hover {
  color: #383838;
  height: 60px;
 }

 @media screen and (max-width: 850px) {
  .icon {
    height: 40px;
  }
  .icon:hover {
    height: 48px;
  }
} 
@media screen and (max-width: 550px) {
  .icon {
    height: 30px;
  }
  .icon:hover{
    height: 36px;
}
} 
@media screen and (max-width: 450px) {
  .icon {
    height: 20px;
  }
  .icon:hover {
    height: 25px;
  }
} 

 @media screen and (max-width: 450px) {
  .respTitle {
    font-size: 35px;
  }
} 

 /* Portfolio Page */

 .align {
  display: flex;
  align-items: center;
}

.border {
  width: 49vw;
  display: inline-block;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 840px) {
  .border {
    width: 100vw;
  }
} 

.projectLinks a {
  text-decoration: none;
  color: #BFB2F3;
}
.projectLinks a:hover {
  text-decoration: underline;
}

.pButtons {
  display: flex;
  flex-wrap: wrap;
}

.prt-btn {
  background-color: inherit;
  color: #BFB2F3;
  margin-left: 0.5vw;
  font-size: 2vh;
  border: 0; 
}
@media screen and (max-height: 500px) {
  .prt-btn {
    font-size: 20px;
  }
}

.prt-btn:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #845ec2;
}

.prt-btn:active {
  color: white;
}

.prt-act-btn {
  background-color: inherit;
  color: #845ec2;
  margin-left: 0.5vw;
  font-size: 2vh;
  border: 0; 
}
@media screen and (max-height: 500px) {
  .prt-act-btn {
    font-size: 20px;
  }
}

.portfolioIcon {
  height: 25vh;
  width: auto;
 }
 @media screen and (max-width: 350px) {
  .portfolioIcon {
    width: 90vw;
    height: auto;
  }
} 
@media screen and (max-height: 500px) {
  .portfolioIcon {
    height: 50vh;
  }
}

 .projectTitle {
  font-size: 3vh;
  color: #ff6a95;
  text-decoration: underline;
  text-align: center;
  margin-bottom: 0px;
 }
 @media screen and (max-height: 500px) {
  .projectTitle {
    font-size: 20px;
  }
}

 .textOver {
  position: absolute;
  background-color: rgba(0,0,0,0.6);
  width: 25vw;
}
@media screen and (max-width: 750px) {
  .textOver {
    width: 40vw;
  }
} 
@media screen and (max-width: 622px) {
  .textOver {
    width: 50vw;
  }
} 
@media screen and (max-width: 505px) {
  .textOver {
    width: 60vw;
  }
} 
@media screen and (max-width: 390px) {
  .textOver {
    width: 90vw;
  }
} 

/* Resume Page */

.stack {
  border: solid 1px #ff6a95;
  margin-left: 3px;
  margin-right: 3px;
 }

 .res {
  height: 100vh;
 }
 @media screen and (max-width: 1000px) {
  .res {
    background-color: #ffffff;
  }
} 

.resumeIss {
  height: 100vh;
 }
 @media screen and (max-width: 1000px) {
  .resumeIss {
    color: #000000;
  }
} 

/* Wall Page */

.grey {
  overflow: hidden;
  height: 88vh;
  width: 98vw;
  padding: 1vh;
  background-color: #404040;
 }

 .grad {
  overflow: hidden;
  height: 92vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: var(--gradientVert);
 }

 .wallText-1 {
  margin-left: 1vw;
  font-weight: 700;
  font-size: 2vh;
  justify-content: space-between;
  color: transparent;
  background-image: var(--gradient1);
  background-clip: text;
  -webkit-background-clip: text;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  /* border-width: 0.5vh;
  border-style: solid;
  border-image: var(--gradient) 1; */
 }

 .wallText-2 {
  margin-left: 1vw;
  font-weight: 700;
  font-size: 2vh;
  justify-content: space-between;
  color: transparent;
  background-image: var(--gradient2);
  background-clip: text;
  -webkit-background-clip: text;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
 }

 .wallText-3 {
  margin-left: 1vw;
  font-weight: 700;
  font-size: 2vh;
  justify-content: space-between;
  color: transparent;
  background-image: var(--gradient3);
  background-clip: text;
  -webkit-background-clip: text;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
 }

 .wallDescription {
  border-bottom: 0.5vh solid;
  border-image: var(--gradient) 1; 
 }

 .descriptionText {
  display: flex;
  align-items: center;
  margin-left: 1vw;
  font-weight: 400;
  font-size: 2vh;
  color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
 }

 /* Loading Page */

 @keyframes fishy {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
display: flex;
align-items: center;
justify-content: center;
animation: fishy 1.5s linear infinite;
}

.topSpace {
margin-top: 30vh;
}